<template>
  <div>
    <base-section id="servicelist-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('serviceSP.mainTitle')" />
            <kdw-service-list-management />
            <!-- @alertSnackBarMessage="alertSnackBarMessage($event)" -->
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component({ name: "ServicelistAccount" })
class ServicelistAccount extends Vue {
  /*
    alertSnackBarMessage (event) {
      this.$root.kdwsnackbar.alert(event.message, event.snackbarTimeout)
    }
    */
}

export default ServicelistAccount;
</script>
